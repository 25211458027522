import pro1 from "../Assets/Project-1.png"
import pro2 from "../Assets/Screenshot 2024-09-14 121913.png"
import pro3 from "../Assets/ComingSoon.jpg"
const ProjectData = [
    {
    imgsrc: pro1,
    title:"Self-Porfolio Website",
    text:" My Personal Portfolio is a dynamic showcase of my skills and experiences, built with React.js and Spring Boot. It offers a comprehensive overview of my educational journey, achievements, and projects.",
    view:"samkporfolio.wuaze.com",
    source:"https://github.com/Sammysk12/my-portfolio.git"
},

{
    imgsrc: pro2,
    title:"Grievance Redressal System",
    text:"Grievance Redressal System: A React.js-based platform designed to empower students to report and resolve hostel issues efficiently. Students can file complaints related to water, light, room conditions, and more. The system facilitates communication between students and authorities, ensuring timely resolution of complaints.",
    view:"#",
    source:"https://github.com/Sammysk12/grievance-redressal-system.git"
},
{
    imgsrc: pro3,
    title: "More Projects Coming Soon.....",
    text:"Currently Working on projects.....",
}

]

export default ProjectData;